import { Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Reply } from '../../@types/api/v0/rest/Reply';
import * as appActions from '../../actions/app';
import { NodeApi } from '../../api/v0/rest/NodeApi';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { WalkthroughApi } from '../../api/v0/rest/WalkthroughApi';
import { ProjectHierarchyApi } from '../../api/v1/bespoke/ProjectHierarchyApi';
import ControlCenter from '../../components/ControlCenter/ControlCenter';
import AnnotationsDrawerContainer from '../../components/Drawers/Annotations/AnnotationsDrawerContainer';
import TimeTravelDrawer, { TimeTravelOption } from '../../components/Drawers/TimeTravel/TimeTravelDrawer';
import { ErrorIcon, FloorsIcon, MarkupsIcon, TimeIcon } from '../../components/Icon';
import { InternalLayout } from '../../components/Layout';
import Content from '../../components/Layout/Content';
import LoadingIndicator from '../../components/LoadingIndicator';
import { PendoTopic } from '../../constants/analytics';
import { NodeQueryKeys, ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import LocationsDrawer from '../../dialogs/LocationsDrawer/LocationsDrawer';
import Routes from '../../routes';
import theme from '../../theme';
import { formatIsoDate } from '../../utils/dateUtils';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import { denormalizeHierarchy } from '../../utils/treeUtils';
import View360Page from './View360Page';

import commonClasses from '../../Common.module.scss';

const View360Container = () => {
  const history = useHistory();
  const urlParameters = useParams<{ projectId: string; floorplanId: string; nodeId: string; walkthroughId: string }>();
  const projectId = Number(urlParameters.projectId);
  const floorplanId = Number(urlParameters.floorplanId);
  const walkthroughId = Number(urlParameters.walkthroughId);
  const nodeId = urlParameters.nodeId;

  const dispatch = useDispatch();

  const {
    isOpen: isAnnotationsDrawerOpen,
    onOpen: openAnnotationsDrawer,
    onClose: closeAnnotationsDrawer,
  } = useDisclosure();
  const { isOpen: isLocationsDrawerOpen, onOpen: openLocationsDrawer, onClose: closeLocationsDrawer } = useDisclosure();
  const {
    isOpen: isTimeTravelDrawerOpen,
    onOpen: openTimeTravelDrawer,
    onClose: closeTimeTravelDrawer,
  } = useDisclosure();

  // Hoisted viewer state:

  const [isLoadingSceneImage, setIsLoadingSceneImage] = useState<boolean>(true);
  // Several code paths require the camera to look at a point on the 3D sphere that encloses the scene. When this value
  // changes, the viewer should respond by orienting the camera on it.
  const [lookTarget, setLookTarget] = useState<{ x: number; y: number; z: number }>();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });
  const projectHierarchyQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_HIERARCHY, projectId],
    queryFn: async ({ signal }) => (await ProjectHierarchyApi.getHierarchyByProjectId(projectId, { signal })).data,
  });
  const walkthroughDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getById(walkthroughId, { signal })).data,
  });
  const walkthroughAnnotationsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getAnnotationsByWalkthroughId(walkthroughId, { signal })).data,
  });
  const nodeMatchingFloorplansQuery = useQuery({
    enabled: isLocationsDrawerOpen,
    queryKey: [QueryTopics.NODES, NodeQueryKeys.MATCHING_FLOORPLANS, nodeId],
    queryFn: async ({ signal }) => (await NodeApi.getMatchingFloorplans(nodeId, { signal })).data,
  });
  const nodeTimeTravelPairsQuery = useQuery({
    enabled: isTimeTravelDrawerOpen,
    queryKey: [QueryTopics.NODES, NodeQueryKeys.TIME_TRAVEL_PAIRS, nodeId],
    queryFn: async ({ signal }) => (await NodeApi.getTimeTravelPairs(nodeId, { signal })).data,
  });

  const projectHierarchy = useMemo(() => {
    if (!projectHierarchyQuery.data || !projectDetailsQuery.data) {
      return undefined;
    }

    return denormalizeHierarchy(projectHierarchyQuery.data, projectDetailsQuery.data.floorplans);
  }, [projectDetailsQuery, projectHierarchyQuery]);

  const queryClient = useQueryClient();

  const criticalQueries = [projectDetailsQuery, projectHierarchyQuery];
  const lowPriorityQueries = [
    walkthroughDetailsQuery,
    walkthroughAnnotationsQuery,
    nodeMatchingFloorplansQuery,
    nodeTimeTravelPairsQuery,
  ];

  const isAnyCriticalQueryFetching = criticalQueries.some((query) => query.isFetching);
  if (isAnyCriticalQueryFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const project = projectDetailsQuery.data;
  const floorplan = projectDetailsQuery.data?.floorplans?.find(({ id }) => id === floorplanId);

  const errorQueries = [...criticalQueries, ...lowPriorityQueries].filter((query) => query.isError);
  if (errorQueries.length > 0 || !project || !projectHierarchy || !floorplan) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this walkthrough. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  const walkthrough = walkthroughDetailsQuery.data;
  const node = walkthrough?.nodes?.find((node) => node.id === nodeId);
  const annotations = walkthroughAnnotationsQuery.data ?? [];
  const nodeAnnotations = walkthroughAnnotationsQuery.data?.filter((annotation) => annotation.node === nodeId);
  const nodeMatchingFloorplans = nodeMatchingFloorplansQuery.data ?? [];
  const nodeTimeTravelPairs = orderBy(nodeTimeTravelPairsQuery.data ?? [], 'when', 'desc');

  const isLoadingSceneData = walkthroughDetailsQuery.isFetching || walkthroughAnnotationsQuery.isFetching;
  const isLoadingScene = isLoadingSceneData || isLoadingSceneImage;

  /** When the user creates an annotation, add it directly to the query data to prevent a loss of context. */
  const handleAnnotationCreate = (annotation: Annotation) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? []).concat(annotation);
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user deletes an annotation, remove it from the query data to prevent a loss of context. */
  const handleAnnotationDelete = (annotation: Annotation) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? []).filter(
      ({ id }) => id !== annotation.id
    );
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user updates an annotation, replace it in the query data to prevent a loss of context. */
  const handleAnnotationUpdate = (annotation: Annotation) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat(annotation);
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user clicks an annotation in 360, open the drawer and focus it. */
  const handleAnnotationViewFrom360 = (annotation: Annotation) => {
    dispatch(appActions.setActiveDrawerItem(annotation.id));
    openAnnotationsDrawer();
  };

  /** When the user clicks "View 360" on an annotation in the drawer, snap to it. */
  const handleAnnotationViewFromDrawer = ({ x, y, z }: Annotation) => {
    setLookTarget({ x, y, z });
  };

  /** Handler called when the user clicks a location within the "Locations" drawer. */
  const handleFloorplanSelect = (location: {
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    nodeId?: string;
    date?: string;
  }) => {
    history.push(
      generateProjectPageUrl(location.projectId, location.floorplanId, location.walkthroughId, location.nodeId)
    );
  };

  /** When the user creates a reply, add it directly to the query data to prevent a loss of context. */
  const handleReplyCreate = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({ ...annotation, replies: [...annotation.replies, reply] });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user deletes a reply, remove it from the query data to prevent a loss of context. */
  const handleReplyDelete = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({ ...annotation, replies: [...annotation.replies.filter(({ id }) => id !== reply.id)] });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /** When the user updates a reply, replace it in the query data to prevent a loss of context. */
  const handleReplyUpdate = (annotation: Annotation, reply: Reply) => {
    const queryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId];
    const currentReplyIndex = annotation.replies.findIndex(({ id }) => id === reply.id);

    const nextAnnotations = (queryClient.getQueryData<Annotation[]>(queryKey) ?? [])
      .filter(({ id }) => id !== annotation.id)
      .concat({
        ...annotation,
        // Ensure the reply is inserted into the correct index.
        replies: [
          ...annotation.replies.slice(0, currentReplyIndex),
          reply,
          ...annotation.replies.slice(currentReplyIndex + 1),
        ],
      });
    queryClient.setQueryData(queryKey, nextAnnotations);
  };

  /**
   * When the user selects a time travel option, verify that the selected option is the correct type, since time travel
   * options may be either `Walkthrough` or `TimeTravelPair` instances. If the option is a `TimeTravelPair` instance,
   * navigate to the walkthrough and node.
   */
  const handleTimeTravel = (timeTravelOption: TimeTravelOption) => {
    if ('walkthrough' in timeTravelOption) {
      history.push(
        generatePath(Routes.VIEW_360, {
          projectId: project.id,
          floorplanId: floorplan.id,
          walkthroughId: timeTravelOption.walkthrough,
          nodeId: timeTravelOption.id,
        })
      );
    } else {
      console.warn('[View360Page] Selected 360 time travel option was not a time travel pair!');
    }
  };

  return (
    <InternalLayout>
      <LocationsDrawer
        floorplan={floorplan}
        isLoading={isLoadingScene || nodeMatchingFloorplansQuery.isFetching}
        isOpen={isLocationsDrawerOpen}
        matches={nodeMatchingFloorplans}
        onClose={closeLocationsDrawer}
        onFloorplanSelect={handleFloorplanSelect}
        project={project}
        projectHierarchy={projectHierarchy}
        walkthrough={walkthrough}
      />
      <TimeTravelDrawer
        isLoading={isLoadingScene || nodeTimeTravelPairsQuery.isFetching}
        isOpen={isTimeTravelDrawerOpen}
        onClose={closeTimeTravelDrawer}
        onSelectOption={handleTimeTravel}
        options={nodeTimeTravelPairs}
        project={project}
        showThumbnails
        walkthrough={walkthrough}
      />
      <AnnotationsDrawerContainer
        annotations={nodeAnnotations}
        isLoading={isLoadingScene}
        isOpen={isAnnotationsDrawerOpen}
        onAnnotationDelete={handleAnnotationDelete}
        onAnnotationUpdate={handleAnnotationUpdate}
        onAnnotationView={handleAnnotationViewFromDrawer}
        onClose={closeAnnotationsDrawer}
        onReplyCreate={handleReplyCreate}
        onReplyDelete={handleReplyDelete}
        onReplyUpdate={handleReplyUpdate}
        project={project}
        walkthrough={walkthrough}
      />
      <Content constrainToPageHeight>
        <ControlCenter
          cardProps={{
            margin: `var(--gutter-page-vertical) var(--gutter-page-horizontal)`,
            position: 'absolute',
            width: 'calc(100% - 2 * var(--gutter-page-horizontal))',
            zIndex: 1,
          }}
          fields={[
            <Button
              data-pendo-label="View annotations"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              flex={1}
              key="annotations-drawer-toggle"
              leftIcon={<Icon as={MarkupsIcon} height="1.25rem" width="1.25rem" />}
              onClick={openAnnotationsDrawer}
              variant="outline"
            >
              Markups
            </Button>,
            <Button
              data-pendo-label="Change location"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="change-locations-drawer-toggle"
              leftIcon={<Icon as={FloorsIcon} height="1.25rem" width="1.25rem" />}
              onClick={openLocationsDrawer}
              variant="outline"
            >
              Locations
            </Button>,
            <Button
              data-pendo-label="Time Travel"
              data-pendo-topic={PendoTopic.CONTROL_CENTER}
              key="time-travel-drawer-toggle"
              leftIcon={<Icon as={TimeIcon} height="1.25rem" width="1.25rem" />}
              onClick={openTimeTravelDrawer}
              variant="outline"
            >
              {walkthrough ? formatIsoDate(walkthrough.when)?.formattedDate : 'Loading...'}
            </Button>,
          ]}
          floorplan={floorplan}
          project={project}
        />
        <View360Page
          annotations={annotations}
          floorplan={floorplan}
          isLoadingScene={isLoadingScene}
          lookTarget={lookTarget}
          node={node}
          onAnnotationCreate={handleAnnotationCreate}
          onAnnotationView={handleAnnotationViewFrom360}
          project={project}
          setIsLoadingSceneImage={setIsLoadingSceneImage}
          setLookTarget={setLookTarget}
          walkthrough={walkthrough}
        />
      </Content>
    </InternalLayout>
  );
};

export default View360Container;
