import { Box, Stat, StatGroup, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { add, format } from 'date-fns';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { ProgressTrackingMilestoneProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { Drawer } from '../../../components/Drawer/Drawer';
import { ChartPoint, ProgressChart } from '../../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../../components/Statistics/PercentChangeStat';
import { DrawerType, PendoTopic } from '../../../constants/analytics';
import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import { generateProjectPageUrl } from '../../../utils/navigationUtils';
import { getRoundedProgressValue } from '../../../utils/progressUtils';

export interface MilestoneProgressDrawerProps {
  /** Flag indicating whether or not the user is authorized to set the baseline completion date for the milestone. */
  canEditMilestoneCompletionDate?: boolean;
  /** Flag indicating whether or not the floorplan/milestone combination has momentum. */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not a loading error has occurred. */
  isError?: boolean;
  /** Flag indicating whether or not data is fetching. */
  isFetching?: boolean;
  /** Flag indicating whether or not the baseline completion date for the milestone is being updated. */
  isMilestoneCompletionDateUpdating?: boolean;
  /** Flag indicating whether or not the baseline completion date for the milestone is currently shown. */
  isMilestoneCompletionDateVisible?: boolean;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current location. May be `undefined` when the drawer is closed or if a summary row cell is clicked. */
  location?: Floorplan | ProjectHierarchyNode;
  /** The current milestone. May be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /** Expected end/completion date for the provided milestone. */
  milestoneCompletionDate?: string;
  /**
   * An object containing summary statistics about the milestone (or milestone-floorplan combination) and a history of
   * progress values.
   */
  milestoneProgressHistory?: ProgressTrackingMilestoneProgressHistory;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** Handler to call when the user selects a new baseline completion date for the milestone. */
  onMilestoneCompletionDateChange: (newDate: string) => void;
  /** ID of the current project. */
  projectId: number;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** Progress value for either the current milestone or the current milestone-floorplan combination. */
  value?: number;
  /** ID of the walkthrough which generated the most recent progress entry. Provided for milestone-floorplan
   * combinations. This value is expected to be `undefined` when the drawer is closed or a summary row cell was clicked.
   */
  walkthroughId?: number;
}

const MilestoneProgressDrawer = (props: MilestoneProgressDrawerProps) => {
  const {
    canEditMilestoneCompletionDate,
    hasMomentum,
    isError,
    isFetching,
    isOpen,
    isMilestoneCompletionDateUpdating,
    isMilestoneCompletionDateVisible,
    isProgressTrackingEnabled,
    location,
    milestone,
    milestoneCompletionDate,
    milestoneProgressHistory,
    onClose,
    onMilestoneCompletionDateChange,
    projectId,
    selectedDate,
    value,
    walkthroughId,
  } = props;

  const history = useHistory();

  const errorContent = (
    <Text>
      An error occurred while loading the progress history for this location. Please try again later. If this issue
      persists, contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const chartPoints = useMemo<ChartPoint[]>(() => {
    if (
      !isProgressTrackingEnabled ||
      !milestoneProgressHistory?.progress_values ||
      milestoneProgressHistory.progress_values.length === 0
    ) {
      return [];
    }

    const result: ChartPoint[] = [];
    for (const entry of milestoneProgressHistory.progress_values) {
      result.push({
        date: new Date(entry.date).getTime(),
        value: entry.value,
      });
    }

    return result;
  }, [isProgressTrackingEnabled, milestoneProgressHistory?.progress_values]);

  const chartCompletionDate =
    isMilestoneCompletionDateVisible && milestoneCompletionDate
      ? new Date(`${milestoneCompletionDate} 12:00:00`)
      : undefined;

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress progress={getRoundedProgressValue(value ?? 0)} progressBarSize="lg" />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={milestoneProgressHistory?.percent_change ?? null} />
          <DurationStat daysElapsed={milestoneProgressHistory?.days_elapsed} />
          {isMilestoneCompletionDateVisible && (
            <Stat>
              <StatLabel id="milestone-progress-baseline-completion-term">Baseline Completion</StatLabel>
              <StatNumber aria-labelledby="milestone-progress-baseline-completion-term" whiteSpace="nowrap">
                {!canEditMilestoneCompletionDate && !milestoneCompletionDate && '-'}
                {!canEditMilestoneCompletionDate &&
                  milestoneCompletionDate &&
                  formatIsoDate(milestoneCompletionDate)?.formattedDate}
                {canEditMilestoneCompletionDate && (
                  <DatePicker
                    buttonProps={{
                      'aria-label': 'Trade Baseline Completion Date',
                      'data-pendo-label': 'Trade Baseline Completion Date',
                      'data-pendo-topic': PendoTopic.PROGRESS_TRACKING,
                      width: { base: '100%', lg: '8.5rem' },
                      minHeight: '2rem',
                      size: 'sm',
                      isLoading: isMilestoneCompletionDateUpdating,
                    }}
                    icon="CalendarIcon"
                    maxDate={add(new Date(), { years: 10 })}
                    onChange={(newDate: Date) => onMilestoneCompletionDateChange(newDate.toISOString().split('T')[0])}
                    popoverPlacement="bottom-end"
                    popoverVariant="searchInput"
                    selected={chartCompletionDate}
                    value={chartCompletionDate ? format(chartCompletionDate, 'MMM d, yyyy') : 'Select a date'}
                  />
                )}
              </StatNumber>
            </Stat>
          )}
        </StatGroup>
      </Box>
      <Box borderBottom={`1px solid ${theme.colors.brand.gray[200]}`} marginBlockStart="1rem" paddingBottom="1.5rem">
        <ProgressChart
          baselineCompletionDate={chartCompletionDate?.getTime()}
          data={chartPoints}
          hasMomentum={hasMomentum}
          highlightLimit={3}
          highlightLimitMaxDate={selectedDate}
          highlightMode="limit"
          minDurationToHighlight={7}
          minHeight="15rem"
          noDataMessage="📈 Data coming soon!"
        />
      </Box>
    </>
  );

  let footerButtonProps;

  // If `location` has a `walkthroughs` key, it is a `Floorplan` and not a `ProjectHierarchyNode`.
  if (location && 'walkthroughs' in location) {
    footerButtonProps = {
      pendoLabel: 'Go to 360 from Milestone Progress drawer',
      onClick: () => {
        history.push(generateProjectPageUrl(projectId, location.id, walkthroughId));
      },
      text: 'Go to 360',
    };
  }

  return (
    <Drawer
      bodyContent={bodyContent}
      drawerType={DrawerType.MILESTONE_PROGRESS}
      errorContent={errorContent}
      footerButtonProps={footerButtonProps}
      isError={isError}
      isFetching={isFetching}
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      pendoTopic={PendoTopic.PROGRESS_TRACKING}
      primaryText={milestone?.name}
      secondaryText={location?.name}
    />
  );
};

export default MilestoneProgressDrawer;
