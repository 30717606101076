import { Center, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Project } from '../../@types/api/v0/rest/Project';
import {
  HierarchyProgressTrackingTableData,
  ProgressTrackingTableData,
} from '../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../@types/api/v1/rest/Milestone';
import { DataTable } from '../../components/DataTable/DataTable';
import { TableCell } from '../../components/DataTable/TableCell';
import LoadingIndicator from '../../components/LoadingIndicator';
import SearchInput from '../../components/SearchInput/SearchInput';
import { PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import FloorNameCell from './FloorNameCell';
import MilestoneProgressDrawerContainer from './MilestoneProgressDrawer/MilestoneProgressDrawerContainer';
import ProgressCell from './ProgressCell';
import { PROGRESS_TABLE_HEADER_TOOLTIPS } from './constants';

export interface ProgressTrackingPageProps {
  /** The current text in the Floor search/filter field. */
  floorSearchTerm?: string;
  /** The current hierarchy node. */
  hierarchyNode?: ProjectHierarchyNode;
  /** Flag indicating whether or not data needed for this component is fetching. */
  isFetching?: boolean;
  /** List of all known milestones. */
  milestones: Milestone[];
  /** Progress data for this project. */
  progress?: ProgressTrackingTableData | HierarchyProgressTrackingTableData;
  /** The current project being displayed. */
  project: Project;
  /** The currently-selected date. Used for time travel and in the URL. */
  selectedDate?: Date;
  /** Function called when the user types into the Floor search/filter field. */
  setFloorSearchTerm: (searchTerm: string) => void;
}

export enum ProgressTrackingRowType {
  SUMMARY,
  FLOORPLAN,
  HIERARCHY_NODE,
}

type SummaryRow = {
  rowType: ProgressTrackingRowType.SUMMARY;
  key: string;
  name: string;
  cells: { hasMomentum: boolean | null; value: number | undefined }[];
};
type FloorplanRow = {
  rowType: ProgressTrackingRowType.FLOORPLAN;
  key: string;
  name: string;
  floorplan: Floorplan;
  cells: { hasMomentum: boolean | null; value: number | undefined; walkthroughId: number }[];
};
type HierarchyNodeRow = {
  rowType: ProgressTrackingRowType.HIERARCHY_NODE;
  key: string;
  name: string;
  hierarchyNode: ProjectHierarchyNode;
  cells: (number | undefined)[];
};

/**
 * Rows on this page's table may be either summary, floorplan, or hierarchy node rows. Note that the `cells` field in
 * all cases below is a sparse array for performance. The array keys are milestone IDs.
 */
export type ProgressTrackingTableRow = SummaryRow | FloorplanRow | HierarchyNodeRow;

const collator = new Intl.Collator('en-US', { numeric: true, sensitivity: 'base' });

const columnHelper = createColumnHelper<ProgressTrackingTableRow>();

const ProgressTrackingPage = (props: ProgressTrackingPageProps) => {
  const {
    floorSearchTerm,
    hierarchyNode,
    isFetching,
    milestones,
    progress: serverProgress,
    project,
    selectedDate,
    setFloorSearchTerm,
  } = props;

  const history = useHistory();

  // RefObject instances are not reactive by design. We want a reactive "ref" to the mounted table's DOM element for
  // scroll restoration, so we need to use a `RefCallback` with state to accomplish this. See `updateTableContainerRef`
  // and secondary effect below.
  const [tableContainer, setTableContainer] = useState<HTMLDivElement>();
  const [tableScrollX, setTableScrollX] = useState<number>(0);
  const [tableScrollY, setTableScrollY] = useState<number>(0);

  const {
    isOpen: isMilestoneProgressDrawerOpen,
    onOpen: onMilestoneProgressDrawerOpen,
    onClose: onMilestoneProgressDrawerClose,
  } = useDisclosure();

  const [progressDrawerProps, setProgressDrawerProps] = useState<{
    row: ProgressTrackingTableRow;
    milestone: Milestone;
  }>();

  const handleProgressCellClick = useCallback(
    (nextProgressDrawerProps: { row: ProgressTrackingTableRow; milestone: Milestone }) => {
      setProgressDrawerProps(nextProgressDrawerProps);
      onMilestoneProgressDrawerOpen();
    },
    [onMilestoneProgressDrawerOpen]
  );

  /** List of milestones which should be used to render the table columns. */
  const milestonesToDisplay = useMemo<Milestone[]>(() => {
    const sortedMilestones = sortBy(milestones, 'order');
    // If the project has no totals entries, show all milestones.
    if (!serverProgress?.totals || serverProgress?.totals?.length === 0) {
      return milestones;
    }

    return sortedMilestones.filter(({ id }) =>
      serverProgress.totals.some((totalsEntry) => totalsEntry.milestone_id === id)
    );
  }, [milestones, serverProgress]);

  const columns = useMemo<ColumnDef<ProgressTrackingTableRow, any>[]>(() => {
    return [
      columnHelper.accessor('name', {
        header: () => (
          <Text as="strong" fontWeight="bold" textStyle="micro" whiteSpace="normal" width="100%">
            Location
          </Text>
        ),
        cell: ({ row }) => {
          if (row.original.rowType === ProgressTrackingRowType.FLOORPLAN) {
            const mostRecentWalkthroughId = maxBy(row.original.floorplan.dated_walkthroughs, 'when')?.id;
            const target = generateProjectPageUrl(project.id, row.original.floorplan.id, mostRecentWalkthroughId);
            return (
              <TableCell reducedPadding>
                <FloorNameCell href={target} onClick={() => history.push(target)}>
                  {row.original.name}
                </FloorNameCell>
              </TableCell>
            );
          }

          // RowType.HIERARCHY_NODE or RowType.SUMMARY
          // For now, if the row is either a hierarchy node or a summary cell, render a simple name.
          return (
            <TableCell reducedPadding>
              <FloorNameCell isSummaryCell>{row.original.name}</FloorNameCell>
            </TableCell>
          );
        },
        sortingFn: (rowA, rowB) => {
          // Summary row:
          if (rowA.original.rowType === ProgressTrackingRowType.SUMMARY) {
            return -1;
          }
          if (rowB.original.rowType === ProgressTrackingRowType.SUMMARY) {
            return 1;
          }

          // Special case: site rows (but only if the row is a floorplan).
          if (
            rowA.original.rowType === ProgressTrackingRowType.FLOORPLAN &&
            rowA.original.name.toLowerCase().includes('site')
          ) {
            return -1;
          }
          if (
            rowB.original.rowType === ProgressTrackingRowType.FLOORPLAN &&
            rowB.original.name.toLowerCase().includes('site')
          ) {
            return 1;
          }

          return collator.compare(rowA.original.name, rowB.original.name);
        },
      }),
      ...milestonesToDisplay.map((milestone) =>
        // @ts-expect-error The columnHelper does not appreciate the dynamically-generated column names
        columnHelper.accessor(`column-${milestone.id}`, {
          header: () => (
            <Tooltip
              label={PROGRESS_TABLE_HEADER_TOOLTIPS[milestone.name.toUpperCase()]}
              openDelay={1000}
              placement="top"
            >
              <Text
                as="strong"
                fontWeight="bold"
                maxWidth="6rem"
                minWidth="2rem"
                flex={1}
                textStyle="micro"
                whiteSpace="normal"
                width="100%"
              >
                {milestone.name}
              </Text>
            </Tooltip>
          ),
          cell: ({ row }) => {
            if (row.original.rowType === ProgressTrackingRowType.SUMMARY) {
              const { hasMomentum, value } = row.original.cells[milestone.id] ?? {};
              return (
                <TableCell
                  boxProps={{
                    ...(hasMomentum === true && {
                      backgroundColor: theme.colors.brand.progressTracking.summaryRowMomentum,
                      _hover: { backgroundColor: theme.colors.brand.progressTracking.summaryRowMomentumHover },
                    }),
                    ...(hasMomentum === false && {
                      backgroundColor: theme.colors.brand.progressTracking.summaryRowDelayWarning,
                      _hover: { backgroundColor: theme.colors.brand.progressTracking.summaryRowDelayWarningHover },
                    }),
                  }}
                  onClick={
                    value !== undefined ? () => handleProgressCellClick({ milestone, row: row.original }) : undefined
                  }
                  pendoLabel="Open milestone progress drawer from total cell"
                  pendoTopic={PendoTopic.PROGRESS_TRACKING}
                >
                  <ProgressCell hasMomentum={hasMomentum} isSummaryCell value={value} />
                </TableCell>
              );
            }

            if (row.original.rowType === ProgressTrackingRowType.FLOORPLAN) {
              const { hasMomentum, value } = row.original.cells[milestone.id] ?? {};
              return (
                <TableCell
                  boxProps={{
                    ...(hasMomentum === true && {
                      backgroundColor: theme.colors.brand.progressTracking.momentum,
                      _hover: { backgroundColor: theme.colors.brand.progressTracking.momentumHover },
                    }),
                    ...(hasMomentum === false && {
                      backgroundColor: theme.colors.brand.progressTracking.delayWarning,
                      _hover: { backgroundColor: theme.colors.brand.progressTracking.delayWarningHover },
                    }),
                  }}
                  onClick={
                    value !== undefined ? () => handleProgressCellClick({ milestone, row: row.original }) : undefined
                  }
                  pendoLabel="Open milestone progress drawer from floor cell"
                  pendoTopic={PendoTopic.PROGRESS_TRACKING}
                >
                  <ProgressCell hasMomentum={hasMomentum} value={value} />
                </TableCell>
              );
            }

            if (row.original.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
              const value = row.original.cells[milestone.id];
              return (
                <TableCell
                  onClick={
                    value !== undefined ? () => handleProgressCellClick({ milestone, row: row.original }) : undefined
                  }
                  pendoLabel="Open milestone progress drawer from hierarchy cell"
                  pendoTopic={PendoTopic.PROGRESS_TRACKING}
                >
                  <ProgressCell value={value} />
                </TableCell>
              );
            }

            return null;
          },
          enableSorting: true,
          sortingFn: (rowA, rowB) => {
            // Summary row:
            if (rowA.original.rowType === ProgressTrackingRowType.SUMMARY) {
              return -1;
            }
            if (rowB.original.rowType === ProgressTrackingRowType.SUMMARY) {
              return 1;
            }

            let rowAProgress: number | undefined;
            let rowBProgress: number | undefined;

            if (rowA.original.rowType === ProgressTrackingRowType.FLOORPLAN) {
              rowAProgress = rowA.original.cells[milestone.id]?.value;
            }
            if (rowB.original.rowType === ProgressTrackingRowType.FLOORPLAN) {
              rowBProgress = rowB.original.cells[milestone.id]?.value;
            }
            if (rowA.original.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
              rowAProgress = rowA.original.cells[milestone.id];
            }
            if (rowB.original.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
              rowBProgress = rowB.original.cells[milestone.id];
            }

            // No data cases
            if (rowAProgress === undefined) {
              return -1;
            }
            if (rowBProgress === undefined) {
              return 1;
            }

            return rowAProgress - rowBProgress;
          },
          sortDescFirst: true,
        })
      ),
    ];
  }, [handleProgressCellClick, history, milestonesToDisplay, project]);

  const rows = useMemo<ProgressTrackingTableRow[]>(() => {
    const result: ProgressTrackingTableRow[] = [];

    // A message will be rendered if the project has no floorplans. Early return.
    if (project.floorplans.length === 0) {
      return result;
    }

    // If there was no progress data (e.g. because there were no walkthroughs or Progress Tracking is disabled), render
    // a row for each floorplan.
    if (!serverProgress?.data || serverProgress.data.length === 0) {
      const floorplans = hierarchyNode?.floorplans ?? project.floorplans;
      for (const floorplan of floorplans) {
        result.push({
          rowType: ProgressTrackingRowType.FLOORPLAN,
          key: `ptd-row-floorplan-${floorplan.id}`,
          name: floorplan.name,
          floorplan,
          cells: [],
        });
      }

      return result;
    }

    // Determine data shape by checking the shape of the first element of the progress data. They should not be mixed.
    // Note that the `in` operator does a runtime check but cannot trigger tighter type inference on its own because TS
    // cannot assume the rest of the array elements are of the same type.
    if ('floor_id' in serverProgress.data[0]) {
      const rawData = serverProgress.data as ProgressTrackingTableData['data'];
      const floorplans = hierarchyNode?.floorplans ?? project.floorplans;
      const floorplanProgress = new Map<number, FloorplanRow['cells']>();

      for (const floorplan of floorplans) {
        const cells: FloorplanRow['cells'] = [];
        result.push({
          rowType: ProgressTrackingRowType.FLOORPLAN,
          key: `ptd-row-floorplan-${floorplan.id}`,
          name: floorplan.name,
          floorplan,
          cells,
        });
        floorplanProgress.set(floorplan.id, cells);
      }

      for (const progressEntry of rawData) {
        const currentCells = floorplanProgress.get(progressEntry.floor_id);
        if (!currentCells) {
          console.warn('[ProgressTrackingPage] Progress data contains unknown floorplan');
          continue;
        }

        currentCells[progressEntry.milestone_id] = {
          hasMomentum: progressEntry.momentum,
          value: progressEntry.value,
          walkthroughId: progressEntry.walk_id,
        };
      }
    }

    if (hierarchyNode && 'hierarchy_node_id' in serverProgress.data[0]) {
      const rawData = serverProgress.data as HierarchyProgressTrackingTableData['data'];
      const childProgress = new Map<number, HierarchyNodeRow['cells']>();

      for (const child of hierarchyNode.children) {
        const cells: HierarchyNodeRow['cells'] = [];
        result.push({
          rowType: ProgressTrackingRowType.HIERARCHY_NODE,
          key: `ptd-row-hierarchy-node-${child.id}`,
          name: child.name,
          hierarchyNode: child,
          cells,
        });
        childProgress.set(child.id, cells);
      }

      for (const progressEntry of rawData) {
        const currentCells = childProgress.get(progressEntry.hierarchy_node_id);
        if (!currentCells) {
          console.warn('[ProgressTrackingPage] Hierarchy progress data contains unknown hierarchy node');
          continue;
        }

        currentCells[progressEntry.milestone_id] = progressEntry.value;
      }
    }

    return result;
  }, [hierarchyNode, project.floorplans, serverProgress?.data]);

  const summaryRow = useMemo<SummaryRow>(() => {
    const cells: { hasMomentum: boolean | null; value: number | undefined }[] = [];
    for (const totalsEntry of serverProgress?.totals ?? []) {
      cells[totalsEntry.milestone_id] = {
        // TODO: until momentum is included in hierarchy totals, the `HierarchyProgressTrackingTableData` type is a lie and `momentum` doesn't exist yet.
        hasMomentum: (totalsEntry.momentum as boolean | null | undefined) ?? null,
        value: totalsEntry.value,
      };
    }

    return {
      rowType: ProgressTrackingRowType.SUMMARY,
      key: 'ptd-summary-row',
      name: hierarchyNode?.name ?? 'Total',
      cells,
    };
  }, [hierarchyNode?.name, serverProgress?.totals]);

  // `RefCallback` function fired when the table's ref changes. Save the current scroll position and maintain a reactive
  // reference to the mounted DOM node.
  const updateTableContainerRef = useCallback(
    (nextRef: HTMLDivElement | null) => {
      if (tableContainer && !nextRef) {
        setTableScrollX(tableContainer?.scrollLeft ?? 0);
        setTableScrollY(tableContainer?.scrollTop ?? 0);
      }

      setTableContainer(nextRef ?? undefined);
    },
    [tableContainer]
  );

  // If the table re-mounts and its last scroll position was saved, scroll back to it. Run on the next animation frame
  // to prevent jank.
  useEffect(() => {
    if (!tableContainer) {
      return undefined;
    }

    const animationFrameId = requestAnimationFrame(() => {
      tableContainer.scroll({
        behavior: 'auto',
        left: tableScrollX,
        top: tableScrollY,
      });
    });

    return () => cancelAnimationFrame(animationFrameId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContainer]);

  const handleDrawerClose = () => {
    onMilestoneProgressDrawerClose();
    setProgressDrawerProps(undefined);
  };

  let pageContent: ReactNode;

  if (isFetching) {
    pageContent = <LoadingIndicator />;
  } else if (project.floorplans.length === 0) {
    pageContent = (
      <Center
        backgroundColor={theme.colors.white}
        borderRadius={theme.radii.card}
        marginBlockStart="1rem"
        padding="2.5rem 1rem"
        data-testid="error-message-no-floorplans"
      >
        <Text color={theme.colors.brand.gray[600]}>
          This project has no floorplans. Please contact our Customer Success team at{' '}
          <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
        </Text>
      </Center>
    );
  } else {
    pageContent = (
      <>
        <Flex paddingBlock="1rem 0.5rem">
          <SearchInput
            onChange={setFloorSearchTerm}
            placeholder="Search floors"
            showClearButton={Boolean(floorSearchTerm)}
            value={floorSearchTerm ?? ''}
          />
        </Flex>
        <DataTable
          data={rows}
          columns={columns}
          defaultSort={{ id: 'name', desc: false }}
          getRowId={(row) => row.key}
          pinnedColumnId="name"
          pinnedRow={summaryRow}
          globalFilter={floorSearchTerm}
          globalFilterFn={(row) => row.original.name.toLowerCase().includes((floorSearchTerm ?? '').toLowerCase())}
          noDataMessage="No data found for this search."
          tableContainerRef={updateTableContainerRef}
        />
        <Text bottom="0.25rem" color={theme.colors.brand.gray[600]} fontSize="0.875rem" position="absolute">
          OnsiteIQ Progress Tracking can make mistakes. Consider verifying important information.
        </Text>
      </>
    );
  }

  return (
    <>
      {pageContent}
      <MilestoneProgressDrawerContainer
        hierarchyNode={hierarchyNode}
        isOpen={isMilestoneProgressDrawerOpen}
        isProgressTrackingEnabled={project.executive_dashboard_enabled}
        milestone={progressDrawerProps?.milestone}
        onClose={handleDrawerClose}
        project={project}
        row={progressDrawerProps?.row}
        selectedDate={selectedDate}
      />
    </>
  );
};

export default ProgressTrackingPage;
