import { Box, Button, Flex, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format, isValid, parse, parseISO } from 'date-fns';
import truncate from 'lodash/truncate';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { ProjectHierarchyNode } from '../../@types/api/v1/bespoke/ProjectHierarchy';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { ProgressTrackingApi } from '../../api/v1/bespoke/ProgressTrackingApi';
import { ProjectHierarchyApi } from '../../api/v1/bespoke/ProjectHierarchyApi';
import { MilestoneApi } from '../../api/v1/rest/MilestoneApi';
import ControlCenter from '../../components/ControlCenter/ControlCenter';
import DatePicker from '../../components/DatePicker/DatePicker';
import { DownloadIcon, ErrorIcon, FloorsIcon } from '../../components/Icon';
import { InternalLayout } from '../../components/Layout';
import Content from '../../components/Layout/Content';
import LoadingIndicator from '../../components/LoadingIndicator';
import Toast from '../../components/Toast';
import { PendoTopic } from '../../constants/analytics';
import { MilestoneQueryKeys, ProgressTrackingQueryKeys, ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import LocationsDrawer from '../../dialogs/LocationsDrawer/LocationsDrawer';
import Routes from '../../routes';
import theme from '../../theme';
import { denormalizeHierarchy, findHierarchyNode } from '../../utils/treeUtils';
import ProgressTrackingPage from './ProgressTrackingPage';
import { PROGRESS_TRACKING_TOOLTIPS } from './constants';

import commonClasses from '../../Common.module.scss';

const ProgressTrackingContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const urlParameters = useParams<{ id: string }>();

  const projectId = Number(urlParameters?.id);
  const hierarchyNodeId = Number(searchParams.get('hierarchyNodeId'));

  const [floorSearchTerm, setFloorSearchTerm] = useState<string>(searchParams.get('floorName') ?? '');
  const [isFirstLoadInProgress, setIsFirstLoadInProgress] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [hierarchyNode, setHierarchyNode] = useState<ProjectHierarchyNode>();

  const { isOpen: isLocationsDrawerOpen, onOpen: openLocationsDrawer, onClose: closeLocationsDrawer } = useDisclosure();

  const toast = useToast();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });
  const projectHierarchyQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_HIERARCHY, projectId],
    queryFn: async ({ signal }) => (await ProjectHierarchyApi.getHierarchyByProjectId(projectId, { signal })).data,
  });
  const milestonesQuery = useQuery({
    queryKey: [QueryTopics.MILESTONES, MilestoneQueryKeys.MILESTONES_LIST],
    queryFn: async ({ signal }) => (await MilestoneApi.list({ signal })).data,
  });
  const progressQuery = useQuery({
    enabled: Boolean(projectDetailsQuery.data?.executive_dashboard_enabled && selectedDate),
    queryKey: [
      QueryTopics.PROGRESS_TRACKING,
      ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
      projectId,
      hierarchyNode?.id,
      selectedDate,
    ],
    queryFn: async ({ signal }) => {
      if (hierarchyNode?.id) {
        return (
          await ProgressTrackingApi.getHierarchyProgressData(hierarchyNode.id, selectedDate!.toISOString(), { signal })
        ).data;
      }
      return (await ProgressTrackingApi.getProgressData(projectId, selectedDate!.toISOString(), { signal })).data;
    },
  });

  const progressDataExportMutation = useMutation({
    mutationKey: [QueryTopics.PROGRESS_TRACKING, ProgressTrackingQueryKeys.PROGRESS_DATA_EXPORT],
    mutationFn: ({ projectId, date }: { projectId: number; date: string }) =>
      ProgressTrackingApi.getProgressDataExport(projectId, date),
    onSuccess: ({ data, headers }) => {
      const csvBlob = new Blob([data], { type: 'text/csv' });
      const tempUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = tempUrl;
      // ProjectName_YYYY-MM-DD_Progress.csv
      tempLink.setAttribute(
        'download',
        headers['Content-Disposition']?.split('filename=')[1] ??
          `${projectDetailsQuery.data?.name.replace(/ /g, '_')}_${format(selectedDate!, 'yyyy-MM-dd')}_Progress.csv`
      );

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(tempUrl);
    },
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          return (
            <Toast {...props} title="Error" description="An error occurred. Please try again later." status="error" />
          );
        },
      });
    },
  });

  const projectHierarchy = useMemo(() => {
    if (!projectHierarchyQuery.data || !projectDetailsQuery.data) {
      return undefined;
    }

    return denormalizeHierarchy(projectHierarchyQuery.data, projectDetailsQuery.data.floorplans);
  }, [projectDetailsQuery.data, projectHierarchyQuery.data]);

  /**
   * List of walkthrough date options which should be presented to the user in the date picker. Note that this is not a
   * comprehensive list of all capture date-times. It is a list of dates when at least one walkthrough of any floorplan
   * occurred, taking the latest timestamp (in the user's locale) on the date.
   */
  const walkthroughDates = useMemo<Date[]>(() => {
    if (!projectDetailsQuery.data) {
      return [];
    }

    const allWalkthroughs = projectDetailsQuery.data.floorplans.flatMap((floorplan) => floorplan.dated_walkthroughs);
    const latestDateTime = new Map<string, Date>();
    for (const walkthrough of allWalkthroughs) {
      const key = walkthrough.when.split('T')[0];
      const captureDate = new Date(walkthrough.when);
      const currentLatestDate = latestDateTime.get(key);

      if (!currentLatestDate || captureDate.getTime() > currentLatestDate.getTime()) {
        latestDateTime.set(key, captureDate);
      }
    }

    return Array.from(latestDateTime.values()).sort((dateA, dateB) => dateA.getTime() - dateB.getTime());
  }, [projectDetailsQuery.data]);

  // When the project details load, validate the date in the URL query parameters. Change if invalid.
  useEffect(() => {
    // If the date has already been extracted from the URL or there are no walkthrough dates, do nothing. In the second
    // case, the project hasn't been walked yet and the page should just show inapplicable dashes.
    if (selectedDate || walkthroughDates.length === 0) {
      return;
    }

    const mostRecentWalkDate = walkthroughDates[walkthroughDates.length - 1];

    // If the date is absent, set the date to the most recent walkthrough date.
    const rawUrlDate = searchParams.get('date');
    if (!rawUrlDate) {
      setSelectedDate(mostRecentWalkDate);
      return;
    }

    // If the date is not valid, set the date to the most recent walkthrough date.
    const parsedDateFromUrl = parseISO(rawUrlDate);
    if (!isValid(parsedDateFromUrl)) {
      setSelectedDate(mostRecentWalkDate);
      return;
    }

    // If the date is valid, find the closest walkthrough date. Stops early on exact matches.
    const currentBestMatch: { date?: Date; diff?: number } = { date: undefined, diff: undefined };
    for (const walkthroughDate of walkthroughDates) {
      const diff = Math.abs(walkthroughDate.getTime() - parsedDateFromUrl.getTime());
      if (diff === 0) {
        setSelectedDate(walkthroughDate);
        return;
      }
      if (currentBestMatch.date === undefined || currentBestMatch.diff === undefined || diff < currentBestMatch.diff) {
        currentBestMatch.date = walkthroughDate;
        currentBestMatch.diff = diff;
      }
    }

    setSelectedDate(currentBestMatch.date ?? mostRecentWalkDate);
  }, [searchParams, selectedDate, walkthroughDates]);

  // When the project hierarchy loads, validate the hierarchy node ID (if any) in the URL query parameters.
  useEffect(() => {
    // Only run if the URL's hierarchy node ID is a positive integer.
    if (!hierarchyNodeId || !Number.isSafeInteger(hierarchyNodeId) || hierarchyNodeId <= 0) {
      return;
    }
    // Only run if project hierarchy has been loaded and denormalized.
    if (!projectHierarchy) {
      return;
    }
    // Only run if the first load has not been completed. If this check was `hierarchyNodeId !== hierarchyNode?.id`, it
    // could cause hierarchy node selection to revert back to hierarchy node matching the URL ID.
    if (!isFirstLoadInProgress) {
      return;
    }

    const node = findHierarchyNode(projectHierarchy, hierarchyNodeId);
    if (node) {
      setHierarchyNode(node);
    }
  }, [hierarchyNodeId, isFirstLoadInProgress, projectHierarchy]);

  // Mark the initial load complete once the requests for project details, milestones list, and first batch of progress
  // (if relevant) are loaded.
  useEffect(() => {
    // If the first load already happened, this effect shouldn't continue.
    if (!isFirstLoadInProgress) {
      return;
    }

    // If the project details, hierarchy, or milestone list are still loading, wait.
    if (projectDetailsQuery.isFetching || projectHierarchyQuery.isFetching || milestonesQuery.isFetching) {
      return;
    }

    // If any critical query failed, loading is complete.
    if (projectDetailsQuery.isError || projectHierarchyQuery.isError || milestonesQuery.isError) {
      setIsFirstLoadInProgress(false);
      return;
    }

    // If Progress Tracking is disabled for the project, loading is complete.
    if (!projectDetailsQuery.data?.executive_dashboard_enabled) {
      setIsFirstLoadInProgress(false);
      return;
    }

    // If the project has never been walked, loading is complete. No need to fire a progress query.
    if (walkthroughDates.length === 0) {
      setIsFirstLoadInProgress(false);
      return;
    }

    // Loading is complete once one of the previous effects sets the `selectedDate` and the progress query settles.
    if (selectedDate && (progressQuery.isError || progressQuery.isSuccess)) {
      setIsFirstLoadInProgress(false);
    }
  }, [
    isFirstLoadInProgress,
    milestonesQuery,
    progressQuery,
    projectDetailsQuery,
    projectHierarchyQuery,
    selectedDate,
    walkthroughDates,
  ]);

  // When the hierarchy node or search term changes, update the URL. Do not run until a selectedDate is present.
  useEffect(
    () => {
      if (!selectedDate) {
        return;
      }

      const nextSearchParams = new URLSearchParams({
        date: selectedDate.toISOString(),
      });

      if (floorSearchTerm) {
        nextSearchParams.set('floorName', truncate(floorSearchTerm, { length: 16 }));
      }
      if (hierarchyNode) {
        nextSearchParams.set('hierarchyNodeId', String(hierarchyNode.id));
      }

      if (nextSearchParams.toString() === location.search.replace('?', '')) {
        return;
      }

      // Replace the URL, instead of calling history.push, to not add a bunch of extra entries into history (which would
      // mess with the browser's "Back" button).
      history.replace(`${location.pathname}?${nextSearchParams.toString()}`);
    },
    // Note: we purposely exclude "location" and "searchParams" from this dependency array to prevent excessive reloads.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floorSearchTerm, hierarchyNode, history, selectedDate]
  );

  if (isFirstLoadInProgress) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const criticalQueries = [projectDetailsQuery, projectHierarchyQuery, milestonesQuery];
  const lowPriorityQueries = [progressQuery];
  const errorQueries = [...criticalQueries, ...lowPriorityQueries].filter((query) => query.isError);
  if (errorQueries.length > 0 || !projectDetailsQuery.data || !milestonesQuery.data || !projectHierarchy) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this project. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  const isAnyLowPriorityQueryFetching = lowPriorityQueries.some((query) => query.isFetching);

  /**
   * Due to either a bug in react-datepicker or developer error, the date picker is operating in *date* mode. When the
   * user selects a different date in the calendar, its *time* portion is still the previous date's time. Find the full,
   * correct `Date` instance for the selection.
   */
  const handleDateChange = (date: Date) => {
    const datePortion = date.toDateString();
    const matchingDate = walkthroughDates.find((walkthroughDate) => walkthroughDate.toDateString() === datePortion);
    setSelectedDate(matchingDate ?? walkthroughDates[walkthroughDates.length - 1]);
  };

  return (
    <InternalLayout>
      <Content
        applyGutters
        constrainToPageHeight
        boxProps={{
          // On mobile and tablet, add more padding to the bottom of the content outlet to support the disclaimer.
          paddingBlockEnd: {
            base: '3rem',
            sm: '2rem', // ~480px
            md: '1.5rem', // ~768px
          },
        }}
      >
        <ControlCenter
          project={projectDetailsQuery.data}
          fields={
            projectDetailsQuery.data.executive_dashboard_enabled && walkthroughDates.length > 0 && selectedDate
              ? [
                  <Button
                    data-pendo-label="Change location"
                    data-pendo-topic={PendoTopic.CONTROL_CENTER}
                    key="change-locations-drawer-toggle"
                    leftIcon={<Icon as={FloorsIcon} height="1.25rem" width="1.25rem" />}
                    onClick={openLocationsDrawer}
                    variant="outline"
                  >
                    Locations
                  </Button>,
                  <DatePicker
                    key="date-picker-field"
                    buttonProps={{
                      'aria-label': 'Time travel date',
                      'data-pendo-label': 'Time travel',
                      'data-pendo-topic': 'progress',
                      isDisabled: isAnyLowPriorityQueryFetching,
                      width: { base: '100%', lg: 'auto' },
                    }}
                    includeDates={walkthroughDates}
                    minDate={parse('2023-03-09', 'yyyy-MM-dd', new Date())}
                    onChange={handleDateChange}
                    popoverPlacement="bottom-end"
                    selected={selectedDate}
                    icon="TimeIcon"
                    tooltipProps={{
                      label: (
                        <Box>
                          <Text marginBlockEnd="1rem">{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_1}</Text>
                          <Text marginBlockEnd="1rem">{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_2}</Text>
                          <Text>{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_3}</Text>
                        </Box>
                      ),
                      placement: 'bottom-end',
                    }}
                    value={format(selectedDate, 'ccc, LLL dd, yyyy')}
                    popoverVariant="searchInput"
                  />,
                  <Button
                    data-pendo-label={`Download Progress data for project ${projectId}`}
                    data-pendo-topic={PendoTopic.PROGRESS_TRACKING}
                    isDisabled={isAnyLowPriorityQueryFetching}
                    isLoading={progressDataExportMutation.isLoading}
                    key="export-csv-button"
                    leftIcon={<Icon as={DownloadIcon} fontSize="1.125rem" />}
                    onClick={() => progressDataExportMutation.mutate({ projectId, date: selectedDate.toISOString() })}
                    variant="outline"
                  >
                    Export
                  </Button>,
                ]
              : undefined
          }
        />
        <ProgressTrackingPage
          floorSearchTerm={floorSearchTerm}
          hierarchyNode={hierarchyNode}
          isFetching={isAnyLowPriorityQueryFetching}
          milestones={milestonesQuery.data}
          progress={progressQuery.data}
          project={projectDetailsQuery.data}
          selectedDate={selectedDate}
          setFloorSearchTerm={setFloorSearchTerm}
        />
      </Content>
      <LocationsDrawer
        hierarchyNode={hierarchyNode}
        isLoading={isAnyLowPriorityQueryFetching}
        isOpen={isLocationsDrawerOpen}
        onClose={closeLocationsDrawer}
        onHierarchyNodeSelect={setHierarchyNode}
        project={projectDetailsQuery.data}
        projectHierarchy={projectHierarchy}
        selectionMode="hierarchyNode"
      />
    </InternalLayout>
  );
};

export default ProgressTrackingContainer;
