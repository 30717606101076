import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { ProjectMilestoneDate } from '../../../@types/api/v1/rest/Project';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { ProjectApi } from '../../../api/v1/rest/ProjectApi';
import Toast from '../../../components/Toast';
import {
  ProgressTrackingQueryKeys,
  ProjectQueryKeys,
  QueryTopics,
  UserManagementQueryKeys,
} from '../../../constants/queries';
import { ProgressTrackingRowType, ProgressTrackingTableRow } from '../ProgressTrackingPage';
import MilestoneProgressDrawer from './MilestoneProgressDrawer';

export interface MilestoneProgressDrawerContainerProps {
  /** The current hierarchy node. */
  hierarchyNode?: ProjectHierarchyNode;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current milestone. May be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The current Progress Tracking table cell's parent row. May be `undefined` when the drawer is closed. */
  row?: ProgressTrackingTableRow;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
}

const MilestoneProgressDrawerContainer = (props: MilestoneProgressDrawerContainerProps) => {
  const { hierarchyNode, isOpen, isProgressTrackingEnabled, milestone, onClose, project, row, selectedDate } = props;

  const milestoneId = milestone && Number.isFinite(milestone.id) ? milestone.id : 0;

  let isSummaryRow = false;
  /** If the row is not a summary row, this will either be a floorplan or hierarchy node. */
  let location: Floorplan | ProjectHierarchyNode | undefined;
  /**
   * If the row is not a summary row, this variable may be used to qualify the `locationId`. Mainly useful for ensuring
   * query key uniqueness.
   */
  let locationType: ProgressTrackingRowType.FLOORPLAN | ProgressTrackingRowType.HIERARCHY_NODE | undefined;
  let hasMomentum: boolean | null | undefined = undefined;
  let value: number | undefined;
  let walkthroughId: number | undefined = undefined;

  if (row?.rowType === ProgressTrackingRowType.FLOORPLAN) {
    location = row.floorplan;
    locationType = row.rowType;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
    walkthroughId = row.cells[milestoneId]?.walkthroughId;
  }
  if (row?.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
    location = row.hierarchyNode;
    locationType = row.rowType;
    value = row.cells[milestoneId];
  }
  if (row?.rowType === ProgressTrackingRowType.SUMMARY) {
    isSummaryRow = true;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
  }

  const isAllLocationsSummaryRow = Boolean(isOpen) && !location && !hierarchyNode && milestoneId > 0;

  const toast = useToast();

  const queryClient = useQueryClient();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const milestoneDatesQuery = useQuery({
    enabled: isAllLocationsSummaryRow,
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_MILESTONE_DATES, project.id],
    queryFn: async ({ signal }) => (await ProjectApi.getMilestoneDates(project.id, { signal })).data,
  });

  const summaryRowProgressHistoryQuery = useQuery({
    enabled: Boolean(isOpen) && isSummaryRow && milestoneId > 0,
    queryKey: [
      QueryTopics.PROGRESS_TRACKING,
      ProgressTrackingQueryKeys.MILESTONE_TOTAL_PROGRESS_HISTORY,
      project.id,
      hierarchyNode?.id,
      milestoneId,
    ],
    queryFn: async ({ signal }) => {
      if (hierarchyNode?.id) {
        return (
          await ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(hierarchyNode.id, milestoneId, { signal })
        ).data;
      }

      return (await ProgressTrackingApi.getTotalMilestoneProgressHistory(project.id, milestoneId, { signal })).data;
    },
  });

  const locationMilestoneProgressHistoryQuery = useQuery({
    enabled: Boolean(isOpen) && !isSummaryRow && milestoneId > 0 && Boolean(location),
    queryKey: [
      QueryTopics.PROGRESS_TRACKING,
      ProgressTrackingQueryKeys.MILESTONE_FLOOR_PROGRESS_HISTORY,
      project.id,
      milestoneId,
      locationType,
      location?.id,
    ],
    queryFn: async ({ signal }) => {
      if (locationType === ProgressTrackingRowType.HIERARCHY_NODE && location) {
        return (
          await ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(location.id, milestoneId, { signal })
        ).data;
      }

      return (
        await ProgressTrackingApi.getFloorMilestoneProgressHistory(project.id, milestoneId, location!.id, { signal })
      ).data;
    },
  });

  const updateMilestoneDateMutation = useMutation({
    mutationKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_MILESTONE_DATE_UPDATE, project.id, milestoneId],
    mutationFn: ({ newDate }: { newDate: string }) =>
      ProjectApi.updateMilestoneDate(project.id, { milestone_id: milestoneId, end_date: newDate }),
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: (_, variables) => {
      const milestoneDatesQueryKey = [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_MILESTONE_DATES, project.id];
      const currentMilestoneDates = milestoneDatesQuery.data ?? [];
      const nextMilestoneDates: ProjectMilestoneDate[] = [
        ...currentMilestoneDates.filter((milestoneDate) => milestoneDate.milestone_id !== milestoneId),
        { milestone_id: milestoneId, end_date: variables.newDate },
      ];

      queryClient.setQueryData(milestoneDatesQueryKey, nextMilestoneDates);
      queryClient.refetchQueries({
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
          project.id,
          hierarchyNode?.id,
          selectedDate,
        ],
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Date updated." status="success" />,
      });

      onClose();
    },
  });

  const currentProgressHistoryQuery = isSummaryRow
    ? summaryRowProgressHistoryQuery
    : locationMilestoneProgressHistoryQuery;
  const milestoneCompletionDate = isAllLocationsSummaryRow
    ? milestoneDatesQuery.data?.find((milestoneDate) => milestoneDate.milestone_id === milestoneId)?.end_date
    : undefined;
  const canEditMilestoneCompletionDate = isAllLocationsSummaryRow
    ? (meQuery.data?.set_trade_dates ?? []).some((account) => account.id === project.account.id)
    : false;

  return (
    <MilestoneProgressDrawer
      canEditMilestoneCompletionDate={canEditMilestoneCompletionDate}
      hasMomentum={hasMomentum}
      isError={milestoneDatesQuery.isError || currentProgressHistoryQuery.isError}
      isFetching={milestoneDatesQuery.isFetching || currentProgressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      isMilestoneCompletionDateUpdating={updateMilestoneDateMutation.isLoading}
      isMilestoneCompletionDateVisible={isAllLocationsSummaryRow}
      location={location}
      milestone={milestone}
      milestoneCompletionDate={milestoneCompletionDate}
      milestoneProgressHistory={currentProgressHistoryQuery.data}
      onClose={onClose}
      onMilestoneCompletionDateChange={(newDate: string) => updateMilestoneDateMutation.mutate({ newDate })}
      projectId={project.id}
      selectedDate={selectedDate}
      value={value}
      walkthroughId={walkthroughId}
    />
  );
};

export default MilestoneProgressDrawerContainer;
