// MARK: Query Topics

export enum QueryTopics {
  ASSET_TYPES = 'ASSET_TYPES',
  AUTH = 'AUTH',
  MILESTONES = 'MILESTONES',
  NODES = 'NODES',
  PORTFOLIO_DASHBOARD = 'PORTFOLIO_DASHBOARD',
  PROCORE = 'PROCORE',
  PROGRESS_TRACKING = 'PROGRESS_TRACKING',
  PROJECTS = 'PROJECTS',
  REPLIES = 'REPLIES',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
}

// MARK: Core API

/** Query keys for user authentication/authorization endpoints. */
export enum AuthQueryKeys {
  USER_PROFILE = 'USER_PROFILE',
}

/** Query keys for milestone endpoints. */
export enum MilestoneQueryKeys {
  MILESTONES_LIST = 'MILESTONES_LIST',
}

/** Query keys for walkthrough node endpoints. */
export enum NodeQueryKeys {
  MATCHING_FLOORPLANS = 'MATCHING_FLOORPLANS',
  TIME_TRAVEL_PAIRS = 'TIME_TRAVEL_PAIRS',
}

/** Query keys for Portfolio Dashboard endpoints. */
export enum PortfolioDashboardQueryKeys {
  PORTFOLIO_PROJECTS_LIST = 'PORTFOLIO_PROJECTS_LIST',
  PROGRESS_HISTORY = 'PROGRESS_HISTORY',
  MILESTONE_PROGRESS = 'MILESTONE_PROGRESS',
  BASELINE_COMPLETION_DATE = 'BASELINE_COMPLETION_DATE',
}

/** Query keys for Progress Tracking endpoints. */
export enum ProgressTrackingQueryKeys {
  MILESTONE_TOTAL_PROGRESS_HISTORY = 'MILESTONE_TOTAL_PROGRESS_HISTORY',
  MILESTONE_FLOOR_PROGRESS_HISTORY = 'MILESTONE_FLOOR_PROGRESS_HISTORY',
  PROGRESS_DATA_EXPORT = 'PROGRESS_DATA_EXPORT',
  PROGRESS_TABLE_DATA = 'PROGRESS_TABLE_DATA',
}

/** Query keys for project-related endpoints, e.g. for projects, their hierarchy, floorplans, and walkthroughs. */
export enum ProjectQueryKeys {
  FLOORPLAN_PROJECT = 'FLOORPLAN_PROJECT',
  MILESTONE_TOTALS = 'MILESTONE_TOTALS',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  PROJECT_HIERARCHY = 'PROJECT_HIERARCHY',
  PROJECT_MILESTONES = 'PROJECT_MILESTONES',
  PROJECT_MILESTONE_DATES = 'PROJECT_MILESTONE_DATES',
  PROJECT_MILESTONE_DATE_UPDATE = 'PROJECT_MILESTONE_DATE_UPDATE',
  WALKTHROUGH = 'WALKTHROUGH',
  WALKTHROUGH_ANNOTATIONS = 'WALKTHROUGH_ANNOTATIONS',
  WALKTHROUGH_DETECTIONS = 'WALKTHROUGH_DETECTIONS',
  WALKTHROUGH_DETECTION_TYPES = 'WALKTHROUGH_DETECTION_TYPES',
  WALKTHROUGH_DETECTION_VOTE = 'WALKTHROUGH_DETECTION_VOTE',
}

/** Query keys for User Management. */
export enum UserManagementQueryKeys {
  ACCOUNT_MEMBERS = 'ACCOUNT_MEMBERS',
  ACCOUNT_MEMBERS_WITH_INTERNAL_USERS = 'ACCOUNT_MEMBERS_WITH_INTERNAL_USERS',
  PROJECT_USERS = 'PROJECT_USERS',
  PROJECT_USERS_WITH_INTERNAL_USERS = 'PROJECT_USERS_WITH_INTERNAL_USERS',
  ACCOUNT_PROJECTS = 'ACCOUNT_PROJECTS',
  ME = 'ME',
  SHARED_ACCOUNTS = 'SHARED_ACCOUNTS',
  SHARED_PROJECTS = 'SHARED_PROJECTS',
  REMOVE_FROM_ACCOUNT = 'REMOVE_FROM_ACCOUNT',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
  RESEND_INVITE = 'RESEND_INVITE',
}

// MARK: Procore

/** Query keys for Procore v1 endpoints. */
export enum ProcoreQueryKeys {
  CONTRIBUTING_BEHAVIORS = 'CONTRIBUTING_BEHAVIORS',
  CONTRIBUTING_CONDITIONS = 'CONTRIBUTING_CONDITIONS',
  COST_CODES = 'COST_CODES',
  HAZARDS = 'HAZARDS',
  LOCATIONS = 'LOCATIONS',
  OBSERVATION_ASSIGNEES = 'OBSERVATION_ASSIGNEES',
  OBSERVATION_DEFAULT_DISTRIBUTION_MEMBERS = 'OBSERVATION_DEFAULT_DISTRIBUTION_MEMBERS',
  OBSERVATION_POTENTIAL_DISTRIBUTION_MEMBERS = 'OBSERVATION_POTENTIAL_DISTRIBUTION_MEMBERS',
  OBSERVATION_TYPES = 'OBSERVATION_TYPES',
  RFI_POTENTIAL_ASSIGNEES = 'RFI_POTENTIAL_ASSIGNEES',
  RFI_POTENTIAL_RESPONSIBLE_CONTRACTORS = 'RFI_POTENTIAL_RESPONSIBLE_CONTRACTORS',
  RFI_POTENTIAL_RECEIVED_FROMS = 'RFI_POTENTIAL_RECEIVED_FROMS',
  SPECIFICATION_SECTIONS = 'SPECIFICATION_SECTIONS',
  TRADES = 'TRADES',
}
