import { AxiosRequestConfig } from 'axios';

import {
  Project,
  ProjectBaselineCompletionDateUpdateRequestBody,
  ProjectBaselineCompletionDateUpdateResponseBody,
  ProjectMilestoneDate,
} from '../../../@types/api/v1/rest/Project';
import { get, patch } from '../../next';

export const ProjectApi = {
  /**
   * Retrieve a project instance by ID.
   * @param id Unique ID of the desired project on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested project.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Project>(`api/v1/rest/projects/${id}/`, requestConfig),
  /**
   * Retrieve the list of dates related to milestone/trades for a project. For example, response bodies may include the
   * expected end/completion dates for trades on the project.
   * @param projectId The ID of the project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested project's milestone dates.
   */
  getMilestoneDates: (projectId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProjectMilestoneDate[]>(`api/v1/rest/projects/${projectId}/milestone-dates/`, requestConfig),
  /**
   * Update a given project's baseline completion date. Users can make this request if they have the `SET_TRADE_DATES`
   * permission for the project's account.
   *
   * @param projectId The ID of the project.
   * @param newDate The date formatted as `YYYY-mm-dd`, e.g. `2023-12-20`.
   * @param requestConfig Optional Axios request configuration overrides.
   */
  updateBaselineCompletionDate: (projectId: number, newDate: string, requestConfig?: AxiosRequestConfig) =>
    patch<ProjectBaselineCompletionDateUpdateRequestBody, ProjectBaselineCompletionDateUpdateResponseBody>(
      `api/v1/rest/projects/${projectId}/baseline-completion-date/`,
      { baseline_completion_date: newDate },
      requestConfig
    ),
  /**
   * Update a milestone/trade date entry for a given project.
   * @param projectId The ID of the project.
   * @param requestBody The milestone ID and date data to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated project's milestone date entry.
   */
  updateMilestoneDate: (projectId: number, requestBody: ProjectMilestoneDate, requestConfig?: AxiosRequestConfig) =>
    patch<ProjectMilestoneDate, ProjectMilestoneDate>(
      `api/v1/rest/projects/${projectId}/milestone-dates/`,
      requestBody,
      requestConfig
    ),
};
